import React, { ReactElement } from 'react';
import './Rules.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et rules-wrapper">
      <h2>Kampaania reeglid</h2>
      <ol>
        <li>
          Fazeri Street Food taskuleibade kampaania on{' '}
          <span className="bold">alates 01.03.2024 kuni 31.03.2024</span> toimuv
          tarbijamäng (edaspidi kampaania), mis leiab aset{' '}
          <span className="bold">
            kõigis Eesti Vabariigi territooriumil asuvates kauplustes
          </span>
          , kus kampaanias osalevaid tooteid müüakse.{' '}
        </li>
        <li>
          Kampaania ja auhinnad korraldab:{' '}
          <span className="bold">Fazer Eesti OÜ</span> (Reg. nr 10057691), Pärnu
          maantee 158/1, 11317 Tallinn, Kawe Ärikeskuse 5. korrus; telefon: +372
          650 2421; e-post: <a href="mailto:info@fazer.ee">info@fazer.ee</a>{' '}
        </li>
      </ol>
      <h3>OSALEMINE ja AUHINNAD</h3>
      <ol>
        <li>
          Kampaania info on kättesaadav aadressil{' '}
          <a href="http://www.fazer.ee">www.fazer.ee</a>
        </li>
        <li>
          Kampaania auhinna loosimises osalemiseks tuleb osta{' '}
          <span className="bold">ükskõik millisest kauplusest üle Eesti </span>{' '}
          vähemalt üks kampaanias osalev{' '}
          <span className="bold">Fazer Street Food taskuleiva toode</span> ja
          registreerida <span className="bold">ostutšeki number</span>{' '}
          veebilehel <a href="https://www.fazer.ee/">www.fazer.ee </a>{' '}
          ajavahemikus <span className="bold">01.03 – 31.03.2024</span> (k.a).{' '}
        </li>
        <li>
          Auhinnafondis{' '}
          <span className="bold">
            on kokku 40 tasuta My Fitness AS spordiklubi ühekordset pääset
            digivautšerina.{' '}
          </span>{' '}
          <span className="bold">
            Iga nädal loositakse välja 10 tasuta My Fitness AS spordiklubi
            ühekordset pääset digivautšerina.{' '}
          </span>{' '}
          Pääsmed kehtivad{' '}
          <span className="bold">
            kuni 31.05.2024 (k.a) kõikides MyFitness spordiklubides.
          </span>
        </li>
        <li>
          Kampaanias osaleja peab registreeritud ostutšeki alles hoidma
          kampaania lõpuni, et esitada see võimaliku võidu kättesaamiseks.
        </li>
        <li>Ühte ostutšekki saab kampaania jooksul registreerida ühe korra.</li>
        <li>
          Osaleja võib teha mitu registreerimist, kui ostab kampaanias osalevaid
          tooteid mitu korda ja registreerib{' '}
          <span className="bold">iga kord uue ostutšeki numbri.</span>
        </li>
        <li>Kampaanias osalevad tooted on:</li>
        <table>
          <thead>
            <tr>
              <td>EAN</td>
              <td>Toote nimetus</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>4750212903588</td>
              <td>Fazer Street Food Tume rukkitaskuleib 330g</td>
            </tr>
            <tr>
              <td>4750212903595</td>
              <td>Fazer Street Food Hele kaerataskuleib 220g</td>
            </tr>
            <tr>
              <td>4750212903571</td>
              <td>Fazer Street Food Seemnepala 240g</td>
            </tr>
          </tbody>
        </table>
        <li>
          Loosimised viiakse läbi loosimistarkvara stat.messenger.ee abil, mis
          võimaldab määrata loosimise täpse perioodi, auhindade kogused ning
          valib võitjad määratud perioodi jooksul registreerunud osalejate seast
          juhuse alusel. Auhinna loosimised toimuvad iga nädal, järgnevatel
          kuupäevadel:
          <span className="block-span">
            08.03.24 (osalevad kõik registreeringud, mis on tehtud vahemikus
            01.03 kl 00:00:00- 07.03 kl 23:59:59);{' '}
          </span>
          <span className="block-span">
            15.03.24 (osalevad kõik registreeringud, mis on tehtud vahemikus
            08.03 kl 00:00:00- 14.03 kl 23:59:59);{' '}
          </span>
          <span className="block-span">
            22.03.24 (osalevad kõik registreeringud, mis on tehtud vahemikus
            15.03 kl 00:00:00- 21.03 kl 23:59:59);{' '}
          </span>
          <span className="block-span">
            01.04.24 (osalevad kõik registreeringud, mis on tehtud vahemikus
            22.03 kl 00:00:00- 31.03 kl 23:59:59).
          </span>
        </li>
        <li>
          Võitjatega võetakse korraldaja esindaja poolt ühendust
          registreerimisvormis märgitud e-posti või telefoni teel võidu
          kinnitamiseks ning auhinna kättetoimetamise täpsustamiseks. Võitjate
          nimed avaldatakse veebilehel{' '}
          <a href="http://www.fazer.ee">www.fazer.ee</a> hiljemalt 3 tööpäeva
          jooksul pärast loosimise kuupäeva.{' '}
        </li>
        <li>
          Juhul, kui võitjatega ei ole õnnestunud ühendust saada{' '}
          <span className="bold">5 tööpäeva jooksul</span> on korraldajal õigus
          auhinnad mitte väljastada ja loosida need välja uutele võitjatele.
        </li>
        <h3>MUUD TINGIMUSED</h3>
        <li>
          Võidetud auhindade kättesaamisega seotud kulutusi ei kompenseerita,
          saadud auhindasid ei asendata teiste auhindadega ega hüvitata selle
          maksumust rahas. Kampaania korraldaja võtab vastutuse kõigi
          auhindadega seotud seadusjärgsete maksude tasumise eest.
        </li>
        <li>
          Auhindade kättesaamiseks peavad võitjad esitama oma isikut tõendava
          dokumendi ning võidu toonud ostutšeki.
        </li>
        <li>
          Kampaanias ei saa osaleda selle korraldamisega seotud inimesed ja
          nende pereliikmed.
        </li>
        <li>
          Kampaania korraldaja ei kanna vastutust selle eest, kui auhindasid
          eeskirjade mittejärgimise tõttu välja ei anta, kui kampaanias osaleja
          esitatud andmed on väärad või ebatäpsed või kui võitjatega ei ole
          võimalik ühendust võtta kampaania korraldajatest mittesõltuvatel
          põhjustel.
        </li>
        <li>
          Kõik kampaaniast tulenevad vaidlused lahendatakse vastavalt Eesti
          Vabariigi seadustele. Vääramatu jõu (force majeure) ilmnemisel on
          korraldajal õigus tarbijamäng ühepoolselt katkestada, teatades sellest
          meedia vahendusel.{' '}
        </li>
        <li>
          Kõik pretensioonid seoses kampaania korraldamise ja/või läbiviimisega
          tuleb saata kirjalikult kampaania korraldaja asukohta:{' '}
          <span className="bold">Fazer Eesti OÜ</span> (Reg. nr 10057691), Pärnu
          maantee 158/1, 11317 Tallinn, Kawe Ärikeskuse 5. korrus; telefon: +372
          650 2421; e-post: <a href="mailto:info@fazer.ee">info@fazer.ee</a> .
          Laekunud pretensioonidele vastatakse kirjalikult 2 nädala jooksul.
          Pretensioone saab esitada kuni 01.04.2024.
        </li>
        <li>
          Lisateavet kampaania kohta saab tööpäevadel{' '}
          <span className="bold">Fazer Eesti OÜ</span> (Reg. nr 10057691), Pärnu
          maantee 158/1, 11317 Tallinn, Kawe Ärikeskuse 5. korrus; telefon: +372
          650 2421; e-post: <a href="mailto:info@fazer.ee">info@fazer.ee</a>
        </li>
        <h3>ANDMETE TÖÖTLEMINE</h3>
        <li>
          Isikuandmete vastutav töötleja on{' '}
          <span className="bold">Fazer Eesti OÜ</span>, Pärnu mnt 158/1, 11317
          Tallinn. Kampaanias osalejatel on õigus saada lisainfot Fazer Eesti OÜ
          poolt isikuandmete töötlemise kohta. Selleks tutvu lähemalt Fazer
          Eesti OÜ privaatsustingimustega siin:{' '}
          <a href="https://www.fazergroup.com/privacy/">
            https://www.fazergroup.com/privacy/
          </a>{' '}
        </li>
        <li>
          Võitjad on veebilehel üleval kuni{' '}
          <span className="bold">30.04.2024.</span>
        </li>
        <li>
          Kogutud andmeid säilitatakse kuni{' '}
          <span className="bold">06.05.2024.</span>
        </li>
      </ol>
    </div>
  );
};

export default Et;
