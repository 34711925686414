import React, { ReactElement, useState } from 'react';
import './Header.scss';
import Logo from '../../assets/images/logo.png';
import Banner from '../../assets/images/banner_w_text.png';
import BannerMobile from '../../assets/images/banner_mobile.png';

import Winners from '../winners/Winners';
import Rules from '../Rules/Rules';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

const Header: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const [winnersActive, setWinnersActive] = useState<boolean>(false);

  const [rulesActive, setRulesActive] = useState<boolean>(false);

  return (
    <div className="Header">
      <Winners
        winnersModalActive={winnersActive}
        setWinnersModalActive={setWinnersActive}
      />

      <Rules
        rulesModalActive={rulesActive}
        setRulesModalActive={setRulesActive}
      />
      <div className="banner-container">
        <img src={Banner} alt="" className="hidden-mobile" />
        <img src={BannerMobile} alt="" className="hidden-desktop" />

        {/* <img src={Logo} alt="" className="logo" /> */}

        {/* <h2>VÕIDA AASTA PIDU 2024!</h2> */}
      </div>
      <div className="description-container container">
        <div className="campaign-description">
          {/* {t('campaign description')} */}
          <p>
            Osta Fazer Street Food sarja tooteid, registreeri ostutšekk fazer.ee
            lehel ja võida 40 tasuta trennipääset
          </p>
          {/* <p className="bold">VÕID VÕITA PEOLAUA VÄÄRTUSEGA 2024€!</p> */}
        </div>

        <div className="campaign-period">
          <span>{t('campaign period')}</span>
        </div>
      </div>
      <div className="nav-container container">
        <HashLink to="#register" className="button">
          {t('register')}
        </HashLink>
        <button
          className="button"
          onClick={() => setWinnersActive(!winnersActive)}
        >
          {t('winners')}
        </button>
        <button className="button" onClick={() => setRulesActive(!rulesActive)}>
          {t('rules')}
        </button>
      </div>
    </div>
  );
};

export default Header;
