import React, { ReactElement } from 'react';
import './Products.scss';
import { useTranslation } from 'react-i18next';
import Leib1 from '../../assets/images/leib_1.png';
import Leib2 from '../../assets/images/leib_2.png';
import Leib3 from '../../assets/images/leib_3.png';
import Leib4 from '../../assets/images/leib_4.png';
import Leib5 from '../../assets/images/leib_5.png';
import Leib6 from '../../assets/images/leib_6.png';
import Leib7 from '../../assets/images/leib_7.png';
import Leib8 from '../../assets/images/leib_8.png';
import Leib9 from '../../assets/images/leib_9.png';

const Products: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div id="products-container" className="Products">
      <div className="columns is-centered">
        <div className="column is-narrow">
          <div className="columns">
            <div className="column">
              <div className="columns is-centered">
                <div className="column is-narrow">
                  <img src={Leib2} alt="bread" />
                </div>
                <div className="column is-narrow">
                  <img src={Leib1} alt="bread" />
                </div>

                <div className="column is-narrow">
                  <img src={Leib3} alt="bread" />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="columns">
            <div className="column">
              <div className="columns is-centered">
                <div className="column is-narrow">
                  <img src={Leib4} alt="bread" />
                </div>
                <div className="column is-narrow">
                  <img src={Leib5} alt="bread" />
                </div>
                <div className="column is-narrow">
                  <img src={Leib6} alt="bread" />
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="columns is-centered">
                <div className="column is-narrow">
                  <img src={Leib8} alt="bread" />
                </div>
                <div className="column is-narrow">
                  <img src={Leib7} alt="bread" />
                </div>

                <div className="column is-narrow">
                  <img src={Leib9} alt="bread" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Products;
